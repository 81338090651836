.pricing_plan_heading {
  font-size: 33px;
  font-weight: 700;
  text-align: center;
}

.pricing_plan_main {
  /* text-align: center; */
  margin-top: 3rem;
}

.pricing_plan_para {
  font-size: 22px;
}

.pricing_logo {
  height: 6rem;
  width: 6rem;
  margin-bottom: 0.5rem;
}

.standard_plan_heading {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0.3rem !important;
}

.standard_plan_descrip {
  font-size: 15px;
  color: #cfcfcf;
  font-weight: 500;
}

.standard_price {
  font-weight: 800;
  font-size: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.3rem;
  margin-top: 0.5rem;
}

.standard_month {
  color: #cfcfcf;
  font-size: 11px !important;
  font-weight: normal !important;
}

.choose_plan_btn {
  border-radius: 1.3rem;
  width: 70%;
}

.standard_plan_main {
  width: 22rem;
  height: 45rem;
  padding: 1rem;
  background-color: white;
  border-radius: 0.5rem;
  margin: 1rem;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.stand_and_plus_main {
  display: flex;
  justify-content: center;
  column-gap: 1rem;
}

.top_feature_heading {
  text-align: start;
}

.plan_good_icon {
  margin-right: 0.5rem;
  font-size: 1rem;
}

.standard_plan_features {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.top_feature_heading {
  font-weight: 800;
}

.standard_plan_feature_heading {
  margin-bottom: 0 !important;
  font-weight: 700;
}
.planCardInfo {
  font-size: 16px;
  color: #929292;
  height: 70px;
}
.largeIcon {
  font-size: 1.4rem;
}

.promo-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.1rem 0;
}

.promo-input {
  width: 70%;
  padding: 0.5rem 1rem;
  border-radius: 1.3rem;
  border: 1px solid #cfcfcf;
  font-size: 14px;
  text-align: center;
  margin-bottom: 0.5rem;
}

.promo-input:focus {
  outline: none;
  border-color: #f3a847;
  box-shadow: 0 0 0 2px rgba(243, 168, 71, 0.2);
}

.promo-input::placeholder {
  color: #cfcfcf;
}
