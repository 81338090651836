.diagram-main {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 1rem;
}

._head {
  font-weight: 600;
  color: #000000;
  font-size: 1.9rem;
  text-align: center;
  margin-bottom: 1rem;
}

.diagram {
  position: relative;
  width: 800px;
  height: 500px;
}

.diagram-image {
  width: 100%;
  height: auto;
}

.label {
  position: absolute;
  background: white;
  border: 1px solid #000;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
}

.check_1 {
  top: 0%;
  left: 3%;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.check_2 {
  top: 9%;
  left: -2%;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.check_3 {
  top: 18%;
  left: -5%;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.check_4 {
  top: 28%;
  left: -16%;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.check_5 {
  top: 37%;
  left: -15%;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.check_6 {
  top: 58%;
  left: -15%;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.check_7 {
  top: 68%;
  left: -5%;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.check_8 {
  top: 77%;
  left: 0%;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.check_9 {
  top: -2%;
  right: 10%;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.check_10 {
  top: 5%;
  right: -1%;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.check_11 {
  top: 13%;
  right: 0%;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.check_12 {
  top: 21%;
  right: -5%;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.check_13 {
  top: 29%;
  right: -13%;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.check_14 {
  top: 45%;
  right: -12%;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.check_15 {
  top: 52%;
  right: -12%;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.check_16 {
  top: 59%;
  right: 0%;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.check_17 {
  top: 66%;
  right: 1%;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.check_18 {
  top: 73%;
  right: 4%;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.check_19 {
  top: 80%;
  right: 7%;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.check_20 {
  top: 88%;
  right: 10%;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

@media (max-width: 1440px) {
  .check_1 {
    top: 0%;
    left: 3%;
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
  .check_2 {
    top: 9%;
    left: 0%;
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
  .check_3 {
    top: 19%;
    left: -7%;
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
  .check_4 {
    top: 29%;
    left: -16%;
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
  .check_5 {
    top: 39%;
    left: -15%;
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
  .check_6 {
    top: 62%;
    left: -14%;
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
  .check_7 {
    top: 72%;
    left: -4%;
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
  .check_8 {
    top: 81%;
    left: 0%;
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
  .check_9 {
    top: -2%;
    right: 12%;
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
  .check_10 {
    top: 6%;
    right: 2%;
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
  .check_11 {
    top: 13%;
    right: 0%;
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
  .check_12 {
    top: 21%;
    right: -5%;
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
  .check_13 {
    top: 29%;
    right: -8%;
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
  .check_14 {
    top: 49%;
    right: -8%;
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
  .check_15 {
    top: 56%;
    right: -4%;
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
  .check_16 {
    top: 63%;
    right: 0%;
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
  .check_17 {
    top: 70%;
    right: 0%;
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
  .check_18 {
    top: 77%;
    right: 4%;
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
  .check_19 {
    top: 85%;
    right: 6%;
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
  .check_20 {
    top: 93%;
    right: 10%;
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
}

.date_input {
  padding: 5px;
  border: 1px solid rgb(197, 197, 197);
  border-radius: 5px;
}

.td_popover {
  position: absolute;
  top: 50px;
  z-index: 999;
  background-color: white;
  border: 3px solid #4c505e;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  padding: 1.5rem;
  border-radius: 10px;
  width: max-content;
  height: max-content;
}
.custom-date-picker-position {
  position: fixed !important;
  top: var(--custom-top) !important;
  left: var(--custom-left) !important;
  z-index: 9999 !important;
}

.hide {
  display: none;
}

.similar_articles,
.all_articles,
.custom_articles {
  font-size: 17px;
  font-weight: 600;
  border-radius: 1.5rem;
  padding: 10px 30px;
  cursor: pointer;
}

.similar_articles {
  border: 2px solid #4c505e;
  color: white;
  background: #4c505e;
}

.all_articles {
  border: 3px solid #4c505e;
  color: #4c505e;
  background: white;
}

.custom_articles {
  border: 3px solid rgb(198, 198, 198);
  color: rgb(113, 113, 113);
  background: white;
}

.year_picker_field {
  width: 100% !important;
  text-align: start !important;
  padding: 0.25rem 0.55rem;
  font-size: 1rem;
  font-weight: 400;
  color: #66799e;
  background-color: #ffffff;
  border: 0.0625rem solid #d1d7e0;
  border-radius: 5px !important;
}
.disabled_year_picker_field,
.disabled_quantity_field {
  background-color: #efefef4c !important;
}

.form_year_picker_field {
  width: 100% !important;
  text-align: start !important;
  padding: 0.25rem 0.55rem;
  font-size: 1rem;
  font-weight: 400;
  color: #66799e;
  background-color: rgba(190, 190, 190, 0.5);
  border: 0.0625rem solid #525252;
  border-radius: 5px !important;
}

.quantity_field {
  width: 80px !important;
  text-align: start !important;
  padding: 0.25rem 0.55rem;
  font-size: 1rem;
  font-weight: 400;
  color: #66799e;
  border: 0.0625rem solid #d1d7e0;
  border-radius: 5px !important;
}

.replace_heading {
  text-align: center !important;
  color: white !important;
  width: 100% !important;
}

.replace_btn_footer_main {
  justify-content: space-around !important;
}

.replace_cancel_btn {
  width: 185px;
  height: 34px;
  color: #333f50;
  border: 2px solid #333f50;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white !important;
}

.replace_artricle_btn {
  background: #333f50 !important;
  color: white;
}

.koyZDM,
.bsLmP {
  overflow-y: visible !important;
  overflow-x: visible !important;
}

.select_option {
  border: 1px solid rgb(199, 199, 199);
  padding: 5px;
  width: 70px;
  outline: none;
}

.price_field {
  width: 110px;
  text-align: start !important;
  padding: 0.25rem 0.55rem;
  font-size: 1rem;
  font-weight: 400;
  color: #66799e;
  border: 0.0625rem solid #d1d7e0;
  border-radius: 5px !important;
}
.components-table thead th {
  text-transform: none;
}
/* .on_boarding_table{
  overflow: visible !important;
} */

.on_boarding_table::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.on_boarding_table::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.on_boarding_table {
  position: relative;
}

.on_boarding_table .rdt_TableCell:last-child {
  position: sticky !important;
  right: 0;
  z-index: 2;
  background-color: #eeeeee;
}

.on_boarding_table .rdt_TableCol:last-child {
  position: sticky !important;
  right: 0;
  z-index: 3;
}
.on_boarding_table .quantity_field::-webkit-outer-spin-button,
.on_boarding_table .quantity_field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.on_boarding_table .react-datepicker-wrapper {
  width: 80px !important;
}
