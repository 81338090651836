.images_display_name {
  width: 14rem !important;
}

.images_checkbox {
  width: 1.3rem !important;
  height: 1.3rem !important;
  margin-right: 0.3rem !important;
}

.property_image {
  width: 4rem;
  height: 4rem;
}

.property_number {
  color: #0091b3;
  font-weight: 600;
  font-size: 14px;
}

.display_name_main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.preview_main {
  margin-top: 0.9rem;
}

.preview_heading {
  font-weight: 700;
  font-size: 14px;
}

.preview_div {
  background-color: white;
  width: 23rem;
  height: 23rem;
  padding-bottom: 1rem;
}

.preview_property_image_main {
  width: 100%;
  height: 90%;
}

.preview_property_image {
  width: 100%;
  height: 100%;
}
.property_created_heading {
  font-weight: 400;
  font-size: 14px;
  width: 41%;
  color: #000000;
}
.property_created_date {
  font-weight: 600;
  font-size: 14px;
  width: 50%;
  color: #333f50;
}

.preview_image_data_main {
  margin: 0.8rem 0.9rem;
}

.created_date_main {
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
}

.copy_icon_main {
  border: 1px solid black;
  background-color: #d9d9d9;
  border-radius: 5px;
  padding: 0.2rem;
  cursor: pointer;
}
.copy_icon {
  font-size: 15px;
}

.access_radio_button > .form-check-input {
  width: 1.3rem !important;
  height: 1.3rem !important;
  color: #000000 !important;
}

.image_preview_default_line {
  display: flex;
  justify-content: center;
  align-items: center;
}

.images_preview_default_line {
  font-size: 14px;
  font-weight: 700;
  color: #000000;
}

.Images_files_action_btn > .btn-toolbar {
  justify-content: end !important;
}
.Images_files_action_btn {
  display: flex;
  justify-content: end !important;
}

.private_public_access_main {
  width: 57%;
  display: flex;
  justify-content: space-between;
}

.Image_files_property {
  vertical-align: middle !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #333f50 !important;
  background: #f5f5f5 !important;
}

.Images_main {
  background-color: white !important;
}

.Images_table_body_main {
  border: 1px solid #d9d9d9 !important;
}

.Images_header_name {
  font-weight: 700 !important;
  font-size: 14px !important;
  color: #000000 !important;
}

.images_files_property_code {
  white-space: break-spaces !important;
}

/* Images Files Page Responsive */
/* For Mobile  And*/
@media only screen and (max-width: 768px) {
  .preview_div {
    width: 100%;
    height: 100%;
  }
}

.Images_files_react_table {
  min-width: 1040px;
  width: 100%;
}
.Images_files_table_main {
  width: 100%;
  overflow-x: auto;
}
.Images_files_react_table .rdt_TableCol {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.Images_files_react_table .rdt_TableHead .rdt_TableHeadRow {
  background-color: #f9fafb;
  font-weight: bold;
  text-align: left;
  padding: 12px;
  border-bottom: 1px solid #d9d9d9 !important;
}

.Images_files_react_table .rdt_TableBody .rdt_TableCell {
  padding: 8px;
  vertical-align: middle;
  background-color: #f5f5f5;
  border-right: 1px solid #d9d9d9;
  border-bottom: none;
}

.Images_files_react_table .rdt_TableBody .rdt_TableCell:first-child {
  background-color: #fff;
  border-left: 1px solid #d9d9d9;
}
.Images_files_react_table .rdt_TableRow:last-child {
  border-bottom: 1px solid #d9d9d9;
}
.Images_files_react_table .rdt_TableBody tr:hover {
  background-color: #eef1f5;
}

.Images_files_react_table .rdt_TableBody tr:last-child .rdt_TableCell {
  border-bottom: none;
}

/* .Images_files_react_table .rdt_TableBody .rdt_TableCell img {
  height: 50px;
  width: auto;
  border-radius: 4px;
  margin-right: 10px;
} */

.Images_files_react_table .rdt_TableBody td a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.Images_files_react_table .rdt_TableBody td a:hover {
  text-decoration: underline;
}

.Images_files_react_table .rdt_TableBody td:first-child {
  display: flex;
  align-items: center;
}

.Images_files_react_table .rdt_TableBody td:first-child .property_image {
  margin-right: 10px;
  height: 50px;
  width: 50px;
  border-radius: 4px;
  object-fit: cover;
}

.Images_files_react_table .rdt_TableBody td:first-child .property_number {
  font-size: 14px;
}
