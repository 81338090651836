.floating-chatbot {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.chat-toggle-btn {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: none;
  background-color: #2196f3;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.chat-toggle-btn:hover {
  transform: scale(1.05);
}

.chat-toggle-btn svg {
  fill: white;
}

.chat-window {
  position: absolute;
  bottom: 80px;
  right: 0;
  width: 350px;
  height: 500px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 5px 40px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.chat-header {
  padding: 16px;
  background: #f8f9fa;
  border-bottom: 1px solid #eee;
}

.chat-title {
  display: flex;
  align-items: center;
  gap: 12px;
}

.bot-avatar {
  font-size: 24px;
  background: #e3f2fd;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-title h3 {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.status {
  font-size: 12px;
  color: #4caf50;
}

.messages-container {
  flex: 1;
  padding: 16px;
  overflow-y: auto;
  background: #f8f9fa;
}

.welcome-message {
  text-align: center;
  color: #666;
  margin: 20px 0;
}

.welcome-message h4 {
  margin: 0 0 8px 0;
}

.welcome-message p {
  margin: 0;
  font-size: 14px;
}

.message {
  max-width: 80%;
  margin: 8px 0;
  padding: 12px;
  border-radius: 12px;
  font-size: 14px;
  line-height: 1.4;
}

.user-message {
  background: #2196f3;
  color: white;
  margin-left: auto;
  border-bottom-right-radius: 4px;
}

.bot-message {
  background: white;
  color: #333;
  margin-right: auto;
  border-bottom-left-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.typing-indicator {
  display: flex;
  gap: 4px;
}

.typing-indicator span {
  width: 8px;
  height: 8px;
  background: #90caf9;
  border-radius: 50%;
  animation: bounce 1.4s infinite ease-in-out;
}

.typing-indicator span:nth-child(1) {
  animation-delay: -0.32s;
}
.typing-indicator span:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.chat-input {
  padding: 12px 16px;
  background: white;
  border-top: 1px solid #eee;
  display: flex;
  gap: 8px;
  align-items: flex-end;
}

.chat-input input {
  flex: 1;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 24px;
  outline: none;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.chat-input input:focus {
  border-color: #2196f3;
}

.chat-input button {
  background: #2196f3;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.chat-input button:hover {
  background: #1976d2;
}

.chat-input button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.chat-input button svg {
  fill: white;
  width: 20px;
  height: 20px;
}

.chat-textarea {
  flex: 1;
  padding: 12px 16px;
  border: 1px solid #ddd;
  border-radius: 24px;
  outline: none;
  font-size: 14px;
  line-height: 1.5;
  resize: none;
  max-height: 120px;
  min-height: 44px;
  transition: all 0.3s ease;
  font-family: inherit;
  background: #f8f9fa;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.chat-textarea:focus {
  border-color: #2196f3;
  background: white;
  box-shadow: 0 2px 8px rgba(33, 150, 243, 0.1);
}

.chat-textarea:disabled {
  background: #e9ecef;
  cursor: not-allowed;
}

/* Custom scrollbar for textarea */
.chat-textarea::-webkit-scrollbar {
  width: 0px;
}

.chat-textarea::-webkit-scrollbar-track {
  background: transparent;
}

.chat-textarea::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 3px;
}

.chat-textarea::-webkit-scrollbar-thumb:hover {
  background: #999;
}

.chat-button-container {
  position: relative;
}

.floating-menu {
  position: absolute;
  bottom: 0px;
  right: 0px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
  width: 200px;
  height: 200px;
}

.chat-button-container:hover .floating-menu {
  visibility: visible;
  opacity: 1;
}

.floating-menu-item {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.chat-button-container:hover .floating-menu-item.open-btn {
  transform: translate(90px, 100px) scale(1);
  background-color: #4caf50;
}

.chat-button-container:hover .floating-menu-item.close-btn {
  transform: translate(150px, 85px) scale(1);
  background-color: #f44336;
}

.floating-menu-item svg {
  fill: white;
}

.floating-menu-item:hover {
  transform: scale(1.1);
}

.chat-button-container .floating-menu {
  transition-delay: 0s;
}

.chat-button-container:hover .floating-menu {
  transition-delay: 0s;
}

.floating-menu {
  transition-delay: 0.3s;
}

.floating-menu-item {
  transform: translate(0, 0) scale(0);
}
